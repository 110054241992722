<template>
  <div class="sws-middle" v-loading="loadingBit" element-loading-background="rgba(255,255,255,0.05)">
    <div class="sws-middle12 sws-modal">
      <div class="sws-model-tool">
        <i class="iconfont prev">&#xe712;</i>
        <i class="iconfont next">&#xe718;</i>
        <i class="iconfont close" @click="close">&#xe70f;</i>
      </div>
      <div class="sws-middle-title">
        {{metarInfo.station_Name}}
      </div>
      <div class="sws-middle-wrap">
        <div class="sws-middle-menu">
          <ul class="pull-left" id="swsStainfoNav">
            <li class="active" @click="slideTo">实况</li>
            <li @click="slideTo" v-if="appFunSetup.NafpBit">逐1小时预报</li>
            <li @click="slideTo" v-if="appFunSetup.NafpBit3h">逐3小时预报</li>
            <li @click="slideTo" v-if="cusStationIntrData.length > 0">防御信息</li>
          </ul>
          <div class="sws-maximize-tool pull-right">
            <div class="sws-maximize-datepicker-group">
              <el-date-picker
                v-model="updateTime"
                type="datetime"
                placeholder="请输入日期时间"
                format="YYYY/MM/DD HH:mm"
                style="width:180px;"
                @change="loadStationData"
              />
            </div>
          </div>
        </div>
        <swiper
          :modules="modules"
          :scrollbar="{ draggable: true }"
          :autoplay="{ delay: 30000 }"
          @swiper="onSwiper"
          @slideChange="onSlideChange"
          class="sws-stainfo"
        >
          <swiper-slide class="sws-stainfo-metar">
            <div class="sws-summary-wrap">
              <div class="sws-summary-item">
                <div class="name">气温：</div>
                <div class="value tem" :style="{color: metarInfo.color.tem}">{{metarInfo.tem}}</div>
              </div>
              <div class="sws-summary-item">
                <div class="name">最低气温：</div>
                <div class="value temmin" :style="{color: metarInfo.color.temMin}">{{metarInfo.temMin}}</div>
              </div>
              <div class="sws-summary-item">
                <div class="name">最高气温：</div>
                <div class="value temmax" :style="{color: metarInfo.color.temMax}">{{metarInfo.temMax}}</div>
              </div>
              <div class="sws-summary-item">
                <div class="name">今日最低温：</div>
                <div class="value temdaymin" :style="{color: metarInfo.color.temDayMin}">{{metarInfo.temDayMin}}</div>
              </div>
              <div class="sws-summary-item">
                <div class="name">今日最高温：</div>
                <div class="value temdaymax" :style="{color: metarInfo.color.temDayMax}">{{metarInfo.temDayMax}}</div>
              </div>
              <div class="sws-summary-item">
                <div class="name">小时降水：</div>
                <div class="value pre1h" :style="{color: metarInfo.color.pre1h}">{{metarInfo.pre1h}}</div>
              </div>
              <div class="sws-summary-item">
                <div class="name">3小时降水：</div>
                <div class="value pre3h" :style="{color: metarInfo.color.pre3h}">{{metarInfo.pre3h}}</div>
              </div>
              <div class="sws-summary-item">
                <div class="name">6小时降水：</div>
                <div class="value pre6h" :style="{color: metarInfo.color.pre6h}">{{metarInfo.pre6h}}</div>
              </div>
              <div class="sws-summary-item">
                <div class="name">08时至今降水：</div>
                <div class="value pre08" :style="{color: metarInfo.color.pre08}">{{metarInfo.pre08}}</div>
              </div>
              <div class="sws-summary-item">
                <div class="name">20时至今降水：</div>
                <div class="value pre20" :style="{color: metarInfo.color.pre20}">{{metarInfo.pre20}}</div>
              </div>
              <div class="sws-summary-item">
                <div class="name">2分钟平均风速：</div>
                <div class="value wins2min" :style="{color: metarInfo.color.wins2min}">{{metarInfo.wins2min}}</div>
              </div>
              <div class="sws-summary-item">
                <div class="name">极大风风速：</div>
                <div class="value winsinst" :style="{color: metarInfo.color.winsInst}">{{metarInfo.winsInst}}</div>
              </div>
              <div class="sws-summary-item">
                <div class="name">相对湿度：</div>
                <div class="value rhu" :style="{color: metarInfo.color.rhu}">{{metarInfo.rhu}}</div>
              </div>
              <div class="sws-summary-item">
                <div class="name">气压：</div>
                <div class="value prs" :style="{color: metarInfo.color.prs}">{{metarInfo.prs}}</div>
              </div>
              <div class="sws-summary-item">
                <div class="name">最小能见度：</div>
                <div class="value vismin" :style="{color: metarInfo.color.visMin}">{{metarInfo.visMin}}</div>
              </div>
            </div>
            <div class="sws-stainfo-metar-chart" v-html="metarChart">

            </div>
          </swiper-slide>
          <swiper-slide v-if="appFunSetup.NafpBit" class="sws-stainfo-nafp">
            <div style="font-size:16px;padding:15px;height:114px;"></div>
            <div class="sws-stainfo-nafp-chart" v-html="gridChart1h">

            </div>
          </swiper-slide>
          <swiper-slide v-if="appFunSetup.NafpBit3h" class="sws-stainfo-nafp">
            <div class="sws-summary-wrap" style="height:114px;">
              <div class="sws-summary-item">
                <div class="name">未来3小时降水：</div>
                <div class="value pre3h" :style="{color: gridMeteo3h.color.pre3h}">{{gridMeteo3h.pre3h}}</div>
              </div>
              <div class="sws-summary-item">
                <div class="name">未来6小时降水：</div>
                <div class="value pre6h" :style="{color: gridMeteo3h.color.pre6h}">{{gridMeteo3h.pre6h}}</div>
              </div>
              <div class="sws-summary-item">
                <div class="name">未来12小时降水：</div>
                <div class="value pre12h" :style="{color: gridMeteo3h.color.pre12h}">{{gridMeteo3h.pre12h}}</div>
              </div>
              <div class="sws-summary-item">
                <div class="name">未来24小时降水：</div>
                <div class="value pre24h" :style="{color: gridMeteo3h.color.pre24h}">{{gridMeteo3h.pre24h}}</div>
              </div>
              <div class="sws-summary-item">
                <div class="name">未来48小时降水：</div>
                <div class="value pre48h" :style="{color: gridMeteo3h.color.pre48h}">{{gridMeteo3h.pre48h}}</div>
              </div>
            </div>
            <div class="sws-stainfo-nafp-chart" v-html="gridChart3h">

            </div>
          </swiper-slide>
          <swiper-slide v-if="cusStationIntrData.length > 0" class="sws-stainfo-intr">
            <div class="sws-stainfo-intr-info">
              <div v-for="(item, index) in cusStationIntrData.filter(tem => tem.IntrType == 'parameter')" :key="index" class="sws-stainfo-intr-parameter">
                <div class="name">{{item.Name}}</div>
                <div class="value">{{item.Val}}</div>
                <div class="remark">{{item.Remark}}</div>
              </div>
              <div v-for="(item, index) in cusStationIntrData.filter(tem => tem.IntrType == 'paragraph')" :key="index" class="sws-stainfo-intr-paragraph">
                <div v-if="item.Name" class="name">{{item.Name}}</div>
                <div class="value">{{item.Val}}</div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, onMounted, watch, ref } from 'vue'
import axios from 'axios'
import common from '@/assets/js/common'
import { Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/css'
import { useStore } from 'vuex'

export default {
  name: 'MapStationInfo',
  components: {
    Swiper,
    SwiperSlide
  },
  props: ['stationInfo', 'type', 'appFunSetup', 'cusInfo', 'gridData'],
  setup (props, { emit }) {
    const store = useStore()
    const state = reactive({
      appCode: store.getters.getAppCode,
      userName: store.getters.getUserName,
      token: store.getters.getToken,
      mySwiper: {},
      metarDataNormal: '',
      appFunSetup: props.appFunSetup,
      stationInfo: props.stationInfo,
      cusInfo: props.cusInfo,
      gridData: props.gridData,
      metarInfo: {
        station_ID_C: '',
        station_Name: '',
        tem: '--',
        temMin: '--',
        temMax: '--',
        temDayMin: '--',
        temDayMax: '--',
        pre1h: '--',
        pre3h: '--',
        pre6h: '--',
        pre08: '--',
        pre20: '--',
        wins2min: '--',
        winsInst: '--',
        rhu: '--',
        prs: '--',
        visMin: '--',
        color: {
          tem: '#ffffff',
          temMin: '#ffffff',
          temMax: '#ffffff',
          temDayMin: '#ffffff',
          temDayMax: '#ffffff',
          pre1h: '#ffffff',
          pre3h: '#ffffff',
          pre6h: '#ffffff',
          pre08: '#ffffff',
          pre20: '#ffffff',
          wins2min: '#ffffff',
          winsInst: '#ffffff',
          rhu: '#ffffff',
          prs: '#ffffff',
          visMin: '#ffffff'
        }
      },
      cusStationIntrData: [],
      // gridMeteo1h: {
      //   cusMeteo: '',
      //   tem: '--',
      //   temMin: '--',
      //   temMax: '--',
      //   temMinCus: '--',
      //   temMaxCus: '--',
      //   pre1h: '--',
      //   pre3h: '--',
      //   pre6h: '--',
      //   pre12h: '--',
      //   winsMaxCus: '--',
      //   visMin: '--',
      //   color: {
      //     tem: '#ffffff',
      //     temMin: '#ffffff',
      //     temMax: '#ffffff',
      //     temDayMin: '#ffffff',
      //     temDayMax: '#ffffff',
      //     pre1h: '#ffffff',
      //     pre3h: '#ffffff',
      //     pre6h: '#ffffff',
      //     pre08: '#ffffff',
      //     pre20: '#ffffff',
      //     wins2min: '#ffffff',
      //     winsInst: '#ffffff',
      //     rhu: '#ffffff',
      //     prs: '#ffffff',
      //     visMin: '#ffffff'
      //   }
      // },
      gridMeteo1h: '',
      gridMeteo3h: {
        cusMeteo: '',
        pre3h: '--',
        pre6h: '--',
        pre12h: '--',
        pre24h: '--',
        pre48h: '--',
        winsmax12h: '--',
        winsmax24h: '--',
        temmin24h: '--',
        temmax24h: '--',
        vismin24h: '--',
        color: {
          pre3h: '#ffffff',
          pre6h: '#ffffff',
          pre12h: '#ffffff',
          pre24h: '#ffffff',
          pre48h: '#ffffff'
        }
      },
      timeVal: [],
      timeText: '',
      metarChart: '',
      gridChart1h: '',
      gridChart3h: '',
      updateTime: ref(''),
      loadingBit: false
    })
    const onSwiper = (swiper) => {
      state.mySwiper = swiper
    }
    const slideTo = (e) => {
      const element = e.target
      const parent = element.parentElement
      const childs = parent.getElementsByTagName('li')
      for (let i = 0; i < childs.length; i++) {
        if (childs[i] === element) {
          state.mySwiper.slideTo(i)
        }
      }
    }
    const onSlideChange = () => {
      const swsStainfoNav = document.getElementById('swsStainfoNav')
      const lis = swsStainfoNav.getElementsByTagName('LI')
      for (let i = 0; i < lis.length; i++) {
        if (i === state.mySwiper.realIndex) {
          lis[i].className = 'active'
          state.timeText = (state.timeVal[i])
        } else {
          lis[i].className = ''
        }
      }
    }
    const loadStationData = () => {
      state.loadingBit = true
      state.metarInfo.station_ID_C = state.stationInfo.staID
      state.metarInfo.station_Name = state.stationInfo.staName
      let time = ''
      if (state.updateTime) {
        time = common.datetimeToStrDate(state.updateTime)
      }
      const type = props.type
      let url
      if (type === 'auto') {
        url = 'https://swsapi.bjzkzm.com/datametar/StaInfo?staId=' + state.stationInfo.staID + '&time=' + time
      } else {
        url = 'https://swsapi.bjzkzm.com/datametar/CusStaInfo?staId=' + state.stationInfo.staID + '&time=' + time
        loadCusStationIntr()
      }

      axios.request({
        method: 'post',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Custom-Token': state.token,
          'User-Card': state.userName,
          'App-Code': state.appCode
        },
        url: url
      }).then(function (res) {
        state.loadingBit = false
        state.updateTime = new Date(res.data.MetarDataNormal.UpdateTime)
        if (res.data.MetarDataNormal != null) {
          state.metarInfo.tem = common.JudgeNum(res.data.MetarDataNormal.TEM, 1)
          state.metarInfo.color.tem = common.getTemColor(res.data.MetarDataNormal.TEM)
          state.metarInfo.temMin = common.JudgeNum(res.data.MetarDataNormal.TEM_Min, 1)
          state.metarInfo.color.temMin = common.getTemColor(res.data.MetarDataNormal.TEM_Min)
          state.metarInfo.temMax = common.JudgeNum(res.data.MetarDataNormal.TEM_Max, 1)
          state.metarInfo.color.temMax = common.getTemColor(res.data.MetarDataNormal.TEM_Max)
          state.metarInfo.temDayMin = common.JudgeNum(res.data.MetarDataNormal.TEM_Min_Day, 1)
          state.metarInfo.color.temDayMin = common.getTemColor(res.data.MetarDataNormal.TEM_Min_Day)
          state.metarInfo.temDayMax = common.JudgeNum(res.data.MetarDataNormal.TEM_Max_Day, 1)
          state.metarInfo.color.temDayMax = common.getTemColor(res.data.MetarDataNormal.TEM_Max_Day)
          state.metarInfo.pre1h = common.JudgeNum(res.data.MetarDataNormal.PRE_1h, 1)
          state.metarInfo.color.pre1h = common.getPreColor(res.data.MetarDataNormal.PRE_1h)
          state.metarInfo.pre3h = common.JudgeNum(res.data.MetarDataNormal.PRE_3h, 1)
          state.metarInfo.color.pre3h = common.getPreColor(res.data.MetarDataNormal.PRE_3h)
          state.metarInfo.pre6h = common.JudgeNum(res.data.MetarDataNormal.PRE_6h, 1)
          state.metarInfo.color.pre6h = common.getPreColor(res.data.MetarDataNormal.PRE_6h)
          state.metarInfo.pre08 = common.JudgeNum(res.data.MetarDataNormal.PRE_0808, 1)
          state.metarInfo.color.pre08 = common.getPreColor(res.data.MetarDataNormal.PRE_0808)
          state.metarInfo.pre20 = common.JudgeNum(res.data.MetarDataNormal.PRE_2020, 1)
          state.metarInfo.color.pre20 = common.getPreColor(res.data.MetarDataNormal.PRE_2020)
          state.metarInfo.wins2min = common.JudgeNum(res.data.MetarDataNormal.WIN_S_Avg_2mi, 1)
          state.metarInfo.winsInst = common.JudgeNum(res.data.MetarDataNormal.WIN_S_INST_Max, 1)
          state.metarInfo.rhu = common.JudgeNum(res.data.MetarDataNormal.RHU, 0)
          state.metarInfo.prs = common.JudgeNum(res.data.MetarDataNormal.PRS, 1)
          state.metarInfo.visMin = common.JudgeNum(res.data.MetarDataNormal.VIS_Min, 0)
        } else {
          state.metarInfo.tem = '--'
          state.metarInfo.color.tem = '#ffffff'
          state.metarInfo.temMin = '--'
          state.metarInfo.color.temMin = '#ffffff'
          state.metarInfo.temMax = '--'
          state.metarInfo.color.temMax = '#ffffff'
          state.metarInfo.temDayMin = '--'
          state.metarInfo.color.temDayMin = '#ffffff'
          state.metarInfo.temDayMax = '--'
          state.metarInfo.color.temDayMax = '#ffffff'
          state.metarInfo.pre1h = '--'
          state.metarInfo.color.pre1h = '#ffffff'
          state.metarInfo.pre3h = '--'
          state.metarInfo.color.pre3h = '#ffffff'
          state.metarInfo.pre6h = '--'
          state.metarInfo.color.pre6h = '#ffffff'
          state.metarInfo.pre08 = '--'
          state.metarInfo.color.pre08 = '#ffffff'
          state.metarInfo.pre20 = '--'
          state.metarInfo.color.pre20 = '#ffffff'
          state.metarInfo.wins2min = '--'
          state.metarInfo.winsInst = '--'
          state.metarInfo.rhu = '--'
          state.metarInfo.prs = '--'
          state.metarInfo.visMin = '--'
        }

        const d = common.metarDataToSvgData(res.data.SurfEle_Hours)
        const strHtml = common.makeSvgMetarHour(840, 240, d)
        state.metarChart = strHtml
        state.mySwiper.slideTo(0)
        state.metarDataNormal = res.data.MetarDataNormal
      })

      if (state.appFunSetup.NafpBit) {
        loadGridPoint('h01')
      }

      if (state.appFunSetup.NafpBit3h) {
        loadGridPoint('h03')
      }
    }

    const loadCusStationIntr = () => {
      const url = 'https://swsapi.bjzkzm.com/DataCusMetar/CusStationIntrData?staId=' + state.stationInfo.staID

      axios.request({
        method: 'post',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Custom-Token': state.token,
          'User-Card': state.userName,
          'App-Code': state.appCode
        },
        url: url
      }).then(function (res) {
        state.cusStationIntrData = res.data
        setTimeout(function () {
          mouseScroll()
        }, 50)
      })
    }

    /**
     * 从格点数据中提取站点的数据
     */
    const getGridVal = (ele, eleType, lon, lat) => {
      const latInt = Math.round((ele.lat_max - ele.lat_min) / (ele.data.length - 1) * 100) / 100 // 源数据纬度间隔
      const lonInt = Math.round((ele.lon_max - ele.lon_min) / (ele.data[0].length - 1) * 100) / 100 // 源数据经度间隔
      const latIndex = ((lat - ele.lat_min) / latInt).toFixed(0) - 0
      const lonIndex = ((lon - ele.lon_min) / lonInt).toFixed(0) - 0
      let u = 0
      let v = 0
      const w = {}

      let res = ''
      switch (eleType) {
        case 'TMP':
          res = (ele.data[latIndex][lonIndex] - 272.15).toFixed(1) - 0
          break
        case 'EDA10':
          u = ele.data_u[latIndex][lonIndex]
          v = ele.data_v[latIndex][lonIndex]
          w.ws = (Math.sqrt(u * u + v * v)).toFixed(1) - 0
          w.wd = Math.round(270 - Math.atan2(v, u) * 180 / Math.PI) // 风向
          w.wd = w.wd >= 360 ? w.wd - 360 : w.wd
          w.wd = w.wd < 0 ? w.wd + 360 : w.wd
          res = w
          break
        default:
          res = ele.data[latIndex][lonIndex].toFixed(1) - 0
          break
      }
      return res
    }

    const loadGridPoint = (gridType) => {
      let lon = Math.round(state.stationInfo.lon * 100)
      let lat = Math.round(state.stationInfo.lat * 100)
      lon = (lon - lon % 5) / 100
      lat = (lat - lat % 5) / 100

      const gridData = state.gridData
      const chartData = []

      if (gridType === 'h01') {
        // do
      } else {
        const gridMeteo3h = {
          pre3h: 0,
          pre6h: 0,
          pre12h: 0,
          pre24h: 0,
          pre48h: 0,
          winsmax12h: 0,
          winsmax24h: 0,
          temmin24h: 999999,
          temmax24h: 0,
          vismin24h: 999999,
          color: {
            pre3h: '#ffffff',
            pre6h: '#ffffff',
            pre12h: '#ffffff',
            pre24h: '#ffffff',
            pre48h: '#ffffff'
          }
        }
        let tems = gridData.TMP03
        let wins = gridData.EDA1003
        let pres = gridData.ER0303
        tems = tems.filter(tem => new Date() < new Date(tem.date_time))
        wins = wins.filter(tem => new Date() < new Date(tem.date_time))
        pres = pres.filter(tem => new Date() < new Date(tem.date_time))

        pres.forEach(function (ele, index, arr) {
          if (index < 24) {
            const cd = {
              UpdateTime: ele.date_time,
              TEM: 999999,
              PRE_1h: getGridVal(ele, 'ER03', lon, lat),
              WIN_D_INST_Max: 999999,
              WIN_S_INST_Max: 999999
            }

            if (tems.length > index && tems[index].date_time === ele.date_time) {
              cd.TEM = getGridVal(tems[index], 'TMP', lon, lat)
            }

            if (wins.length > index && wins[index].date_time === ele.date_time) {
              const w = getGridVal(wins[index], 'EDA10', lon, lat)
              cd.WIN_S_INST_Max = w.ws
              cd.WIN_D_INST_Max = w.wd
            }
            chartData.push(cd)

            if (index === 0) {
              gridMeteo3h.pre3h = cd.PRE_1h
            }

            if (index < 2) {
              gridMeteo3h.pre6h += cd.PRE_1h
            }

            if (index < 4) {
              gridMeteo3h.pre12h += cd.PRE_1h
            }

            if (index < 8) {
              gridMeteo3h.pre24h += cd.PRE_1h
            }

            if (index < 16) {
              gridMeteo3h.pre48h += cd.PRE_1h
            }
          }
        })

        chartData.reverse()
        state.gridMeteo3h = gridMeteo3h
        const d = common.metarDataToSvgData(chartData)
        let strHtml = common.makeSvgMetarHour(840, 240, d)
        strHtml = strHtml.replace('极大风', '风')
        state.gridChart3h = strHtml
      }
    }

    const gridDataToMeteo = (gridData) => {
      if (gridData.length > 0) {
        let res = '未来' + gridData.length + '小时'
        if (gridData.some(tem => tem.TEM !== 999999)) {
          const temMax = common.maxObj(gridData, 'TEM')
          const temMaxTime = new Date(temMax.UpdateTime)
          res += '最高气温' + temMax.TEM + '℃(' + temMaxTime.getDate() + '日' + temMaxTime.getHours() + '时)，'
          const temMin = common.minObj(gridData, 'TEM')
          const temMinTime = new Date(temMin.UpdateTime)
          res += '最低气温' + temMin.TEM + '℃(' + temMinTime.getDate() + '日' + temMinTime.getHours() + '时)，'
        }
        if (gridData.some(tem => tem.PRE_1h !== 999999)) {
          if (gridData.some(tem => tem.PRE_1h > 0)) {
            const preMax = common.maxObj(gridData, 'PRE_1h')
            const preMaxTime = new Date(preMax.UpdateTime)
            let preTotal = 0
            gridData.filter(tem => tem.PRE_1h > 0).forEach(function (ele, index, arr) {
              preTotal += ele.PRE_1h
            })
            res += '累积降水' + preTotal + 'mm，' + '最大小时降水' + preMax.PRE_1h + 'mm(' + preMaxTime.getDate() + '日' + preMaxTime.getHours() + '时)，'
          } else {
            res += '无降水，'
          }
        }
        if (gridData.some(tem => tem.WIN_S_INST_Max !== 999999)) {
          const winMax = common.maxObj(gridData, 'WIN_S_INST_Max')
          const winMaxTime = new Date(winMax.UpdateTime)
          res += '最大风' + common.getWindDirTxt8(winMax.WIN_D_INST_Max) + common.getWindPower(winMax.WIN_S_INST_Max) + '级(' + winMaxTime.getDate() + '日' + winMaxTime.getHours() + '时)。'
        }
        return res
      } else {
        return ''
      }
    }
    const mouseScroll = () => {
      const wraps = document.getElementsByClassName('sws-stainfo-intr-info')
      for (let i = 0; i < wraps.length; i++) {
        wraps[i].addEventListener('wheel', function (event) {
          event.preventDefault()

          const deltaY = event.deltaY
          if (deltaY > 0) {
            wraps[i].scrollTop = wraps[i].scrollTop + 50
          } else if (deltaY < 0) {
            wraps[i].scrollTop = wraps[i].scrollTop - 50
          }
        })
      }
    }

    const close = () => {
      emit('close')
    }
    onMounted(() => {
      loadStationData()
    })
    watch(() => props.staID, () => {
      loadStationData()
    })
    return {
      ...toRefs(state),
      modules: [Autoplay],
      slideTo,
      onSwiper,
      onSlideChange,
      loadGridPoint,
      close,
      loadStationData,
      loadCusStationIntr,
      gridDataToMeteo
    }
  }
}
</script>
